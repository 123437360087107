import {PROJECTOR} from "./constants";

export const hideProjector = () => {
    PROJECTOR.style.display = 'none';
}

export function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getRegionDomain() {
    const hostname = window.location.hostname;

    if (hostname.includes('apicore.kz')) return 'kz';
    if (hostname.includes('apicore.uz')) return 'uz';
    if (hostname.includes('apicore.kg')) return 'kg';
    if(hostname.includes('apicore.dev')) return 'dev';

    return 'one';
}