import {useTranslation} from "react-i18next";

const AuthCountiesModal = () => {

	const {t} = useTranslation();
	const countries = [
		{
			id: 'kz',
			name: t('I_KZ'),
			url: 'https://apicore.kz/'
		},
		{
			id: 'uz',
			name: t('I_UZ'),
			url: 'https://apicore.uz/'
		},
		{
			id: 'kg',
			name: t('I_KG'),
			url: 'https://apicore.kg/'
		}
	]

	// Визуализация компоненты
	return (
		<div className={'i_auth_countries'}>
			<div className="i_form-title">
				<span>{t('I_COUNTRIES')}</span>
			</div>

			{countries && countries.map(({id, url, name}) => (
				<a href={url} key={id}>
					<div className="i_form-item">
						<span>{name}</span>
					</div>
				</a>
			))}

			{/*<a href="https://apicore.kz/">
				<div className="i_form-item">
					<span>{t('I_KZ')}</span>
				</div>

			{/*<a href="https://apicore.kz/">
				<div className="i_form-item">
					<span>{t('I_KZ')}</span>
				</div>
			</a>

			<a href="https://apicore.uz/">
				<div className="i_form-item">
					<span>{t('I_UZ')}</span>
				</div>
			</a>

			<a href="https://apicore.kg/">
				<div className="i_form-item">
					<span>{t('I_KG')}</span>
				</div>
			</a>*/}

		</div>
	);
}

export default AuthCountiesModal;
