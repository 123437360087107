import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import FooterInfo from "./FooterInfo";

const Footer = () => {

	const {t} = useTranslation();
	const dispatch = useDispatch();

	const openContacts = () => {
		dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'contacts', modalIsOpen: true})
	};

	const docsLinksData = [
		{
			key: 'users',
			title: `${t('I_DOC_USERS')}`,
			users: [
				{
					key: 'distributor',
					title: `${t('I_DOC_DISTRIBUTOR')}`,
					link: 'https://docs.apicore.one/cabinet/distributor',
				},
				{
					key: 'dealer',
					title: `${t('I_DOC_DEALER')}`,
					link: 'https://docs.apicore.one/cabinet/dealer',
				}
			],
		},
		{
			key: 'developers',
			title: `${t('I_DOC_DEVS')}`,
			users: [
				{
					key: 'distributor',
					title: `${t('I_DOC_DISTRIBUTOR_API')}`,
					link: 'https://docs.apicore.one/api/distributor',
				},
				{
					key: 'dealer',
					title: `${t('I_DOC_DEALER_API')}`,
					link: 'https://docs.apicore.one/api/dealer',
				}
			],
		}
	]

	return (
		<>
			<div className="i_bottom">
				<div className="i_bottom_left">

					{docsLinksData && docsLinksData.map(({key, title, users}) => (
						<div key={key} className="i_bottom_left-item">
							<div className={'footer-docs-title'}>{title}: &nbsp;</div>
							<br/>
							{users && users.map(({key, title, link}, index) => (
								<div key={key}>
									<Link to={link} target="_blank">{title} &nbsp;</Link>
									{index < users.length - 1 && <span>{t('I_&')} &nbsp;</span>}
								</div>
							))}
						</div>
					))}
					<div className="i_bottom_left-item">
						<div className="i_social">
							<Link to="https://www.instagram.com/apicore.one/" className="i_instagram"
							      target="_blank"></Link>
							<Link to="https://www.facebook.com/apicore.one/" className="i_facebook"
							      target="_blank"></Link>
						</div>
					</div>


				</div>
				<div className="i_bottom_center">
					<nav>
						<Link to="/payment">{t('I_TERMS_OF_PAYMENT')}</Link>
						<Link to="/privacy">{t('I_PRIVACY_POLICY')}</Link>
						<Link to="/offerta">{t('I_OFFERTA')}</Link>
					</nav>
				</div>
				<div className="i_bottom_right">
					<FooterInfo />
					<div className={'i_bottom_contacts'} onClick={openContacts}>
						<span>{t('I_CONTACTS')}</span>
					</div>
					<div className="i_social">
						<Link to="https://www.instagram.com/apicore.kz/" className="i_instagram" target="_blank"></Link>
						<Link to="https://www.facebook.com/apicore.kz" className="i_facebook" target="_blank"></Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer;