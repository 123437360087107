import logo from '../img/closeModal.svg';
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import i18n from "../i18n";
import useLocalStorage from "../hooks/useLocalStorage";
import avatar from "../img/avatar.svg";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RegionSelector from "../features/RegionSelector/RegionSelector";
import {useEffect} from "react";



const BurgerMenu = ({isBurgerOpen, setIsBurgerOpen, setIsOpen, isOpen}) =>{
	const [region, setRegion] = useLocalStorage('');
	const {t} = useTranslation();
	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();
	const [language, setLanguage] = useLocalStorage('language', 'ru')
	const dispatch = useDispatch();

	const handleSetRegion = () => {
		switch (lastSegment) {
			case 'kz':
				setRegion(t('I_KZ'));
				break;
			case 'uz':
				setRegion(t('I_UZ'));
				break;
			case 'one':
				setRegion('Global');
				break;
			case 'kg':
				setRegion(t('I_KG'));
				break;
			default:
				setRegion(t('I_KZ'));
				break;
		}
	}

	useEffect(() => {
		handleSetRegion()
	}, [lastSegment])


	const setCurrentLanguage = (el) =>{
		i18n.changeLanguage(el)
		setLanguage(el)
		window.location.reload();
	}

	const authorized = useSelector(state => state.userData.authorization);

	return(
		<div className={`i_burger ${isBurgerOpen ? 'open' : ''}`}>
			<div className="i_auth_form-close" onClick={()=>{setIsBurgerOpen(!isBurgerOpen)}}>
				<img src={logo} alt="close"/>
			</div>

			{authorized ?
				<>
					<div className="i_header_account">
						<a href={`${lastSegment === 'd' ? 'http' : 'https'}://cabinet.${currentDomain}`} className='i_cabinet_button'>
							<img src={avatar} alt="user"/>
							<span>{t('I_ACCOUNT')}</span>
						</a>
					</div>
				</>

				:
				<>
					<div className={`i_header_account`} onClick={() => {
						setIsBurgerOpen(!isBurgerOpen)
						dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: lastSegment === 'one' ? 'authCountiesModal' : 'authFormModal', modalIsOpen: true, isAuth: false})
					}}>{t('I_ACCOUNT')}</div>
				</>
			}

			<div className="i_burger_items">
				<div className="i_burger_item" onClick={()=>{setIsBurgerOpen(!isBurgerOpen)}}>
					<Link to="/for_distributors">{t('I_DISTRIBUTORS')}</Link>
				</div>
				<div className="i_burger_item" onClick={()=>{setIsBurgerOpen(!isBurgerOpen)}}>
					<Link to="/for_resellers">{t('I_RESELLERS')}</Link>
				</div>
			</div>

			<div className={'i_change_region'}>
				<RegionSelector currentRegion={region} region={region} burger={true} />
			</div>

			<div className='i_change_language'>
				<LanguageSelector setCurrentLanguage={setCurrentLanguage} language={language} burger={true}/>
			</div>

		</div>
	)
}

export default BurgerMenu;