import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {ReactComponent as LangIcon} from "../img/icons/lang-icon-1.svg";
import {ReactComponent as BurgerArrow} from '../img/burger-language-arrow.svg';

const LanguageSelector = ({ setCurrentLanguage, language, burger }) => {
	const { t } = useTranslation();
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(language);
	const dropdownRef = useRef(null);

	useEffect(() => {
		setSelectedLanguage(language);
	}, [language]);

	const languageOptions = [
		{ value: 'en', label: 'En'},
		{ value: 'ru', label: 'Ру'},
	];

	const handleToggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const handleSelectLanguage = (value) => {
		setSelectedLanguage(value);
		setCurrentLanguage(value);
		setDropdownOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [])


	return (
		<div className="custom-language-selector" ref={dropdownRef}>
			<div
				className={`selected-language ${isDropdownOpen ? 'open' : ''}`}
				onClick={handleToggleDropdown}
				aria-expanded={isDropdownOpen}
				role="button"
				tabIndex={0}
			>
				<LangIcon />
				{t(languageOptions.find((option) => option.value === selectedLanguage)?.label)}
				<div className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`}><BurgerArrow/></div>
			</div>

			{isDropdownOpen && (
				<div className="language-options">
					{languageOptions
						.filter((option) => option.value !== selectedLanguage)
						.map((option) => (
						<div
							key={option.value}
							className="language-option"
							onClick={() => handleSelectLanguage(option.value)}
						>
							{t(option.label)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default LanguageSelector;
